import React, { Component } from "react";
import Layout from "../../components/layout/layout.js";
import Module from "../../components/structure/Module/module.js";
import Wrapper from "../../components/structure/Wrapper/wrapper.js";
import Column from "../../components/structure/Column/column.js";
import Box from "../../components/modules/Box/box.js";
import Seo from "../../components/seo/seo.js";
import {Title} from "../../components/elements/Title/title";
import {Breadcrumbs} from "../../components/modules/Breadcrumbs/breadcrumbs";
import {Rating} from "../../components/modules/Rating/rating";
import RichText from "../../components/modules/RichText/richText.js";
import WorkTime from "../../components/modules/WorkTime/workTime.js";
import LeafletMap from "../../components/modules/Map/leafletMap.js";
import Faq from "../../components/modules/Faq/faq";
import JsonBreadcrumbs from "../../components/seo/ld-json/jsonBreadcrumbs.js";
import AdsenseManager from '../../components/modules/Adsense/AdsenseManager';
import About from '../../components/modules/About/about.js';
import Jumbotron from "../../components/modules/Jumbotron/jumbotron.js";
import { Button } from "../../components/elements/Button/button.js";
import Tags from "../../components/modules/Tags/tags.js";
import getClassName from "../../components/modules/Rating/helperRating";
import IconBlock from "../../components/modules/IconBlock/iconBlock.js";
import GalleryImages from "../../components/modules/GalleryImages/index.js";
import {Opinions} from "../../components/modules/Opinions/opinions";
class Object extends Component {

    render() {
        const data = this.props.pageContext.data;
        const seo = this.props.pageContext.seo;
        const textStatic = this.props.pageContext.textStatic;
        const rating = true;//this.props.pageContext.rating || false;
        const country_code = seo.country_code;
        const mainTitle = seo.mainTitle || false;
        const subTitleMap = seo.subTitleMap || false;
        const mainText = this.props.pageContext.mainText || false;
        const asks = this.props.pageContext.asks || false;
        const adsense = this.props.pageContext.adsense;
        const gallery = this.props.pageContext.gallery || false;
        const logo = this.props.pageContext.logo;
        const source_name = this.props.pageContext.source_name;
        const image_bg = this.props.pageContext.image_bg;
        const type = this.props.pageContext.type;

        const jsontld = {
            "data": {
                "name": `${mainTitle}`,
                "phone": `${data.phone}`,
                "address": `${data.street}`,
                "url": `${data.site}`,
            }
        };

        const dataMap = [
            {
                "name": data.name,
                "latitude": data.latitude,
                "longitude": data.longitude,
            }
        ];

        let site_url_clean_name = (data.site && data.site.includes("?")) ?
            data.site.split('?')[0] :
            data.site;

        site_url_clean_name = (site_url_clean_name && site_url_clean_name.length > 35) ? site_url_clean_name.substr(0, 34) + '...' : site_url_clean_name;

        const info = [
            [data.name, data.type_name, false, type.icon],
            [data.street, data.postal_code + ', '+data.region_1_name, false, 'icon-gym-map'],
            [data.phone, site_url_clean_name, data.site, 'icon-gym-contact']
        ];

        let obj = {};

        const keyServices = textStatic['object_services_text'];

        const optionsServices = data.about[keyServices];

        if (keyServices in data.about) {
            obj[keyServices] = optionsServices;
            delete data.about[keyServices];
        }

        return (
            <Layout>
                <Seo lang={seo.lang} title={seo.title} url={seo.url} image={seo.image} description={seo.description} robotsIndex={seo.robotsIndex} robotsFollow={seo.robotsFollow}/>
                <Module withoutPad bg>
                    <Wrapper withoutPad>
                        <Column adapt>
                            <Breadcrumbs content={data.breadcrumbs} host={seo.host}/>
                            <JsonBreadcrumbs data={data.breadcrumbs} host={seo.host}/>
                        </Column>
                    </Wrapper>
                </Module>

                <Jumbotron
                    bg={image_bg}
                    title={mainTitle}
                    rating={rating && <Rating
                        textStatic={textStatic}
                        country_code={country_code}
                        object_id={data.id}
                        type_id={data.type_id}
                        region_1_slug={data.region_1_slug}
                        region_2_slug={data.region_2_slug}
                        source_name={source_name}
                        jsontld_parent={jsontld}
                        jsontld_parent_type="Organization"
                    />}
                    logo={logo}
                />
                <Module>
                    <Wrapper middle>
                        <Column row>
                            <Column>
                                <Box border>
                                    <Column row>
                                        <IconBlock info={info} />
                                        <Column col='half' colSm='full'>
                                            {(data.booking_appointment_link && data.booking_appointment_link !== "") && <Button primary blank link={`${data.booking_appointment_link}`} content={textStatic.object_book_title}  />}
                                        </Column>
                                    </Column>
                                </Box>
                                <AdsenseManager adsense={adsense}/>

                                <RichText content={mainText} />

                                <Title tag={`h2`} size18 content={`${textStatic.object_foto_title} ${data.type_name} ${data.name}`} />
                                <GalleryImages images={gallery} />

                                <AdsenseManager adsense={adsense}/>

                                <Title tag={`h2`} size18 content={textStatic.object_category_title} />
                                <Tags tags={data.subtypes} />

                                <Title tag={`h2`} size18 content={textStatic.object_all_services_title} />
                                <About about={obj} boxItems/>
                                <About about={data.about}/>
                                <WorkTime data={data.working_hours} title={textStatic.office_subtitle_work_time}/>

                                <AdsenseManager adsense={adsense}/>

                                <Title tag={`h2`} size18 content={subTitleMap} />
                                <LeafletMap dataMap={dataMap} textStatic={textStatic} zoom={15}/>

                                <Faq asks={asks} title={textStatic.faq_title}/>

                                <AdsenseManager adsense={adsense}/>

                                <Title tag={`h2`} size18 content={`${data.type_name_plural} ${textStatic.office_subtitle_map_other}`}/>
                                <LeafletMap dataMap={data.closet_offices} textStatic={textStatic} zoom={15}/>

                                <Title size20 tag="h2" content={textStatic.opinions_title} className="text-align--center marg-t60" />

                                <Opinions
                                    textStatic={textStatic}
                                    seo={seo}
                                    country_code={country_code}
                                    count={100}
                                    pagination={20}
                                    object_id={data.id}
                                    typePage={"object"}
                                />

                            </Column>
                        </Column>
                    </Wrapper>
                </Module>
            </Layout>
        );
    }
}

export default Object;