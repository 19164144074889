import React, { useState, useEffect } from 'react'
import { Adsense } from '@ctrl/react-adsense'

export default function AdsenseManager({adsense}) {

    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(true);
    }, []);

    return (
        <>
            {adsense.slot && show ?
                <Adsense
                client={adsense.pub}
                slot={adsense.slot}
                format='auto'
                style={{ display: 'block'}}
                layout='in-article'
                responsive={true}
                />
                : null
            }
        </>

    );
}