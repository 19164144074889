import React from "react";
import "./about.scss";
import { Title } from "../../elements/Title/title";
import { Icon } from "../../elements/Icon/icon";

const About = ({ about, boxItems }) => {
    return (
        <div className="m-about">
            {
                Object.keys(about).map((keyFirst, iFirst) => (
                    <div key={iFirst}>
                        <Title tag={`h3`} size16 content={`${keyFirst}:`} />
                        <div className={boxItems&&"m-about__items--box"}>
                            {
                                Object.keys(about[keyFirst]).map((keySecond, iSecond) => (
                                    <p> 
                                        {about[keyFirst][keySecond] ? 
                                            <Icon icon={'icon-check.png'} width='16' /> 
                                        : 
                                            <Icon icon={'icon-cross.png'} width='16' />}
                                        {keySecond}    
                                    </p>
                                ))
                            }
                        </div>
                    </div>
                ))
            }
        </div>
    );
};

export default About;