import React from "react";
import classNames from "classnames";
import "./icon.scss";

export const Icon = props => {
    const classnames = classNames("m-icon", {
        "m-icon--circle": props.circle,
        "m-icon--shadow": props.shadow,
        "m-icon--inline-block": props.inlineBlock,
        "m-icon--vertical-middle": props.verticalMiddle,
    });
    return (
        <img className={props.className ? props.className + ` ` + classnames : classnames}
             src={'/images/icons/'+ props.icon}
             width={props.width}
             height={props.height}
             alt={props.icon}
         />
    );
};
