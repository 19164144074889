import React, {useEffect, useState} from 'react';
import "./rating.scss";
import { Text } from "../../elements/Text/text";
import { RatingModal } from "./ratingModal";
import getClassName from './helperRating';

const { getHash } = require ("../../../helpers/getHash/getHash");

export const Rating = props => {

    const country_code = props.country_code;
    const object_id = props.object_id;
    const type_id = props.type_id || false;
    const region_1_slug = props.region_1_slug || false;
    const region_2_slug = props.region_2_slug || false;
    const source_name = props.source_name || false;
    const jsontld_parent = props.jsontld_parent;
    const jsontld_parent_type = props.jsontld_parent_type;

    const textStatic = props.textStatic;

    const [canRender, setCanRender] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [voted, setVoted] = useState(false);
    const [productRating, setProductRating] = useState(false);
    const [productRatingCount, setProductRatingCount] = useState(false);
    const [hash, setHash] = useState(false);
    const [elements, setElements] = useState(false);

    const ldjson_rating = {
        "@context": "http://schema.org",
        "@type": "AggregateRating",
        "itemReviewed": {
            "type": jsontld_parent_type,
            "name": jsontld_parent.data.name
        },
        "ratingValue": productRating,
        "reviewCount": productRatingCount,
        "worstRating": "1",
        "bestRating": "5"

    };

    const countStars = 5;

    useEffect(() => {
        if (!hash) {
            setHash(getHash(30));
        }
        getRating(country_code, object_id);
        setElements(document.getElementsByClassName("icon-rating"));
        setCanRender(true);
    }, [object_id]);

    const toggleModal = () => {
        setOpenModal(!openModal);
    };

    const onMouseOver = event => {
        if (!voted && elements) {
            Object.keys(elements).forEach(function(key, value) {
                if (elements[key].dataset.count <= event.target.dataset.count) {
                    elements[key].classList.add("user_vote_select");
                }
                elements[key].classList.add("user_select");
            });
        }
    };

    const onMouseOut = () => {
        if (!voted && elements) {
            Object.keys(elements).forEach(function(key, value) {
                elements[key].classList.remove("user_vote_select");
                elements[key].classList.remove("user_select");
            });
        }

    };

    const onClick = event => {
        if (!voted && elements) {
            Object.keys(elements).forEach(function(key, value) {
                if (elements[key].dataset.count <= event.target.dataset.count) {
                    elements[key].classList.add("user_vote_select");
                }
            });
            setVoted(true);
            toggleModal();
            saveRating(country_code, object_id, type_id, event.target.dataset.count, false, false, source_name, window.location.pathname, region_1_slug, region_2_slug);
        }
    };

    const onSendForm = (username, comment) => {
        saveRating(
            country_code,
            object_id,
            type_id,
            false,
            comment,
            username
        );
    };

    return (
        <div className={"m-rating m-rating-usable"}>

            {canRender && (
                <>

                    {openModal && (<RatingModal textStatic={textStatic} handler={toggleModal} onSendForm={onSendForm}/>)}

                    {productRating && (
                        <div>
                            <span className="">{productRating}</span>
                            {[...Array(countStars)].map((event, i) =>
                                <i
                                    data-count={i+1}
                                    className={'icon-rating user_vote ' + getClassName(i+1, productRating)}
                                    onClick={event => onClick(event)}
                                    onMouseEnter={event => onMouseOver(event)}
                                    onMouseOut={() => onMouseOut()}
                                    aria-hidden="true"
                                    onBlur={ () => void 0 }
                                />

                            )}
                        </div>

                    )}

                    {productRatingCount && (<Text size10 colorThird content={productRatingCount + " " + textStatic.rating_count} />)}

                    {productRatingCount && (
                        <script
                            type="application/ld+json"
                            dangerouslySetInnerHTML={{ __html: JSON.stringify(ldjson_rating) }}
                        />
                    )}

                </>
            )}

        </div>
    );

    function getRating(country_code, object_id) {

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({
                country_code: country_code ,
                object_id: object_id,
                comments: false,
            })
        };

        fetch('https://allgym.work/get-rating', requestOptions)
            .then(response => response.json())
            .then(data => {
                setProductRating(data.rating);
                setProductRatingCount(data.count);
            });
    }

    function saveRating(country_code, object_id, type_id, rating, comment, username, source_name, source_url, region_1_slug, region_2_slug) {

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({
                country_code: country_code,
                object_id: object_id,
                type_id: type_id,
                rating: rating,
                comment: comment,
                username: username,
                source_name: source_name,
                source_url: source_url,
                hash: hash,
                region_1_slug: region_1_slug,
                region_2_slug: region_2_slug
            })
        };

        fetch('https://allgym.work/save-rating', requestOptions)
            .then(response => response.json())
            .then(data => {
                toggleModal();
            });
    }
};