import React from "react";
import Gallery from 'react-grid-gallery';
import './galleryImages.scss';

const GalleryImages = ({ images }) => {
    return(
        <Gallery
            images={images}
            tagStyle={{
                display: "inline",
                padding: ".2em .6em .3em",
                fontSize: "75%",
                fontWeight: "600",
                lineHeight: "1",
                color: "#7deab8",
                background: "rgba(0,0,0,0.65)",
                textAlign: "center",
                whiteSpace: "nowrap",
                verticalAlign: "baseline",
                borderRadius: ".25em"
            }}
            thumbnailStyle={function(){
                return ({
                    "object-fit": 'cover',
                    "cursor": "pointer",
                    "width": 180,
                    "height": 180,
                    "marginLeft": 0,
                    "marginTop": 0,
                });
            }}
            enableLightbox={true}
            backdropClosesModal
            enableImageSelection={false}
        />
    );
}

export default GalleryImages;