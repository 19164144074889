import React from "react";
import './tags.scss';
import Tag from "../../elements/Tag/tag";

const Tags = props => {

    const arrayTags = props.tags.split(",").map(element => {
        return element.trim();
    });

    return(
        <div className="m-tags">
            {arrayTags.map((tag) => (
                <Tag text={tag} />
            ))}
        </div>
    );
}

export default Tags;