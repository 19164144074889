import React from "react";
import "./iconBlock.scss";
import Column from "../../structure/Column/column";
import { Title } from "../../elements/Title/title";

const IconBlock = ({info}) => {
    return(
        <div className="m-icon-block">
            {info.map((data, key) => {
                return(
                    <Column key={key} col='half' colSm='full' className={key !== info.length-1 && 'marg-b32'}>
                        <div className="m-icon-block__body">
                            <i className={`icon-gym ${data[3]}`}/>
                            <div>
                                <Title size16 className='marg-t0 m-icon-block__title' content={data[0]} />
                                { data[2]?
                                    <a className="e-link" href={data[2]}>{data[1]}</a>
                                :
                                    <p>{data[1]}</p>
                                }
                            </div>
                        </div>
                    </Column>
                );
            })}
        </div>
    );
    
}

export default IconBlock;