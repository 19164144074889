import React from "react";
import "./workTime.scss";
import {Title} from "../../elements/Title/title";

export const WorkTime = props => {
    const title = props.title;

    let dataFormated = [];
    let isShow = false;

    if (Object.entries(props.data).length > 3) {

        isShow = true;

        for (const [key, value] of Object.entries(props.data)) {

            let newItem = {
                "day" : key,
                "work_time" : value
            };

            dataFormated.push(newItem)
        }
    }

    return (
        <div>
            {isShow && <Title tag={`h2`} size18 content={title} />}

            {isShow &&
            <ul className={"m-work-time"}>
                {dataFormated.map((value, index) => (
                    <li key={index}>
                        <span className={"m-work-time--name"}>{value.day}:</span> {value.work_time}
                    </li>
                ))}
            </ul>
            }
        </div>
    );
};

export default WorkTime;